import { useCallback, useEffect, useState, useMemo } from "react";
import { useApi } from "providers/api";
import { format } from "date-fns";
import {
  onChangeStatusCheck,
  onCheckAppointmentToRegistration,
} from "utils/appointmentCheck";
import {
  AppointmentStatus,
  RegistrationStatusGroup,
} from "type/appointmentStatus";
import { useSnackbar } from "SnackbarProvider";
import { useImperativeModal } from "ImperativeModalProvider";
import { ImmediateSmsConfirmModal } from "modals/ImmediateSmsConfirmModal";
import { ConfirmModal } from "components/Modal/ConfirmModal";
import storeAppointments from "store/appointments";
import { workTimeCheckByDate } from "utils/workTimeCheck";
import { WorkTimeConfirmModal } from "modals/WorkTimeConfirmModal";
import {
  externalUpdateConflictErrorMessage,
  validateCanceledNaverAppointment,
} from "utils/linkedChannelUtil";

export const useAppointment = ({
  date,
  customerId,
  initOrder,
  departmentId: propDepartmentId,
  clinic,
}: {
  date?: any;
  customerId?: any;
  initOrder?: any;
  departmentId: null | string;
  clinic?: { workEnd: string };
}) => {
  const api = useApi();
  const sb = useSnackbar();
  const imperativeModal = useImperativeModal();
  const { setDepartments: setStoreDepartments } = storeAppointments;

  const [departmentId, setDepartmentId] = useState<null | number>(
    propDepartmentId !== null ? JSON.parse(propDepartmentId) : propDepartmentId
  );
  const [departments, setDepartments] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [count, setCount] = useState({
    total: 0,
    established: 0,
    new: 0,
    canceled: 0,
    appointment: 0,
    today: 0,
  });
  const [order, setOrder] = useState(initOrder.order ?? "asc");
  const [orderTarget, setOrderTarget] = useState(
    initOrder.target ?? "startHour"
  );
  const [subOrder, setSubOrder] = useState(initOrder.sub ?? "");
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const limit = 25;

  const orderBy = useMemo(
    () =>
      subOrder
        ? `${orderTarget} ${order} ${`, ${subOrder}`}`
        : `${orderTarget} ${order}`,
    [order, orderTarget, subOrder]
  );

  const loadDepartments = useCallback(async () => {
    const res = await api.getDepartments();
    const payload = await res.json();

    if (payload.data) {
      const departments = payload.data.map((v: any) => v.departments).flat();
      setDepartments(departments);
      setStoreDepartments(departments);
    }
  }, [api]);

  const loadCustomerAppointments = useCallback(
    async ({ orderBy, page }: { orderBy?: any; page?: any }) => {
      const params = {
        customerId,
        orderBy,
        limit: page * limit,
        progresses: "OPEN",
      };
      const res = await api.getSessions(params);
      const payload = await res.json();
      if (payload.data) {
        setAppointments(payload.data);
        setHasMore(payload.total >= page * limit);
      }
    },
    [api, customerId, limit]
  );

  const loadAppointments = useCallback(
    async ({
      date,
      orderBy,
      page,
    }: {
      date?: any;
      orderBy?: any;
      page?: any;
    }) => {
      const params: any = {
        dateStart: format(new Date(date), "yyyy-MM-dd"),
        dateEnd: format(new Date(date), "yyyy-MM-dd"),
        limit: page * limit,
        progresses: "OPEN",
      };
      if (departmentId) params.departmentId = departmentId;
      if (orderBy) params.orderBy = orderBy;
      const res = await api.getSessionsSimple(params);
      const payload = await res.json();
      if (payload.data) {
        setAppointments(payload.data);
        setHasMore(payload.pagination.total >= page * limit);
      }
    },
    [api, limit, departmentId]
  );

  const loadCount = useCallback(
    async (date: any, departmentId: any) => {
      const res = await api.getAppointmentsCount(date, departmentId);
      const payload = await res.json();
      if (payload.data) {
        setCount(payload.data);
      }
    },
    [api]
  );

  const reload = useCallback(async () => {
    setPage(1);
    if (customerId) {
      loadCustomerAppointments({ orderBy, page });
    } else {
      loadCount(date, departmentId);
      loadAppointments({ date, orderBy, page });
    }
  }, [
    customerId,
    loadCustomerAppointments,
    orderBy,
    loadCount,
    date,
    departmentId,
    loadAppointments,
    page,
  ]);

  const updateOrder = useCallback(
    async (column: any, options: any) => {
      if (orderTarget === column) {
        setOrder(order === "asc" ? "desc" : "asc");
      } else {
        setOrderTarget(column);
        setOrder("desc");
      }
      setSubOrder(options);
    },
    [order, setOrder, orderTarget, setOrderTarget]
  );

  const updateDepartmentId = (id: any) => {
    setDepartmentId(id);
    setPage(1);
  };

  const updatePage = () => {
    if (!hasMore) return;
    setPage((p) => (p += 1));
  };

  const updateAppointment = (id: any, updateData: any) => {
    const appointmentsData: any = [...appointments];
    const targetIndex = appointments.findIndex((v: any) => v.id === id);
    appointmentsData[targetIndex] = {
      ...appointmentsData[targetIndex],
      ...updateData,
    };
    setAppointments(appointmentsData);
  };

  useEffect(() => {
    loadCount(date, departmentId);
  }, [date, departmentId, loadCount]);

  useEffect(() => {
    if (customerId) {
      loadCustomerAppointments({ orderBy, page });
    } else {
      loadAppointments({ date, orderBy, page });
    }
  }, [
    date,
    departmentId,
    orderBy,
    loadAppointments,
    loadCustomerAppointments,
    customerId,
    page,
  ]);

  useEffect(() => {
    loadDepartments();
  }, [loadDepartments]);

  const onChangeRestoreAppointment = async (id: number, sendSms: any) => {
    const params = {
      sendSms: sendSms,
      status: "SCHEDULED",
    };
    try {
      await api.resotreAppointment(id, params);
      sb.open("예약으로 변경되었습니다.");
      reload();
    } catch (e: any) {
      if (e.code === 500 && e.message === "Out of working hours") {
        return sb.open("진료시간을 초과했습니다.");
      }
      if (e.code === 409 && e.message === "EXTERNAL UPDATE CONFLICT") {
        return sb.open(externalUpdateConflictErrorMessage);
      }
      return sb.open(e.message);
    }
  };

  const checkImmediateSms = async (smsRules: any, appointment: any) => {
    const confirmed = await imperativeModal.open((close) => (
      <ImmediateSmsConfirmModal
        smsRules={smsRules}
        onConfirm={() => close(true)}
        onCancel={() => close(false)}
      />
    ));
    if (confirmed) {
      onChangeRestoreAppointment(
        appointment.id,
        smsRules.map((v: any) => v.id)
      );
    } else {
      onChangeRestoreAppointment(
        appointment.id,
        smsRules
          .filter((f: any) => f.smsScheduleType !== "immediate")
          .map((v: any) => v.id)
      );
    }
  };

  const onChangeStatus = async (appointment: any, changeStatus: any) => {
    const { id, status, type, externalLinkedChannel, externalRequest } =
      appointment;
    try {
      validateCanceledNaverAppointment(
        status,
        externalRequest?.status,
        externalLinkedChannel
      );
    } catch (e: any) {
      return sb.open(e.message);
    }

    let updated = null;
    if (type === "APPOINTMENT") {
      const targetStatus =
        changeStatus.type === AppointmentStatus.additionalStatus
          ? AppointmentStatus.additionalStatus
          : changeStatus.code;
      if (RegistrationStatusGroup.indexOf(targetStatus) > -1) {
        // 2. 예약 -> 접수로 변경하는 경우 POST /registrations
        const msg = onCheckAppointmentToRegistration(status, targetStatus);
        if (msg !== undefined) {
          sb.open(msg);
          return;
        }

        const handleChangeStatus = async () => {
          const payload = {
            appointmentId: appointment.id,
            status: targetStatus,
            sessionStatusConfigId:
              targetStatus === AppointmentStatus.additionalStatus
                ? changeStatus.id
                : undefined,
          };
          const resp = await api.createRegistrationSimple(payload);
          updated = await resp.json();
        };

        if (
          clinic &&
          workTimeCheckByDate(
            appointment.endAt,
            appointment.startAt,
            clinic.workEnd
          )
        ) {
          const resp = await imperativeModal.open((close) => (
            <WorkTimeConfirmModal close={close} />
          ));
          if (resp) {
            await handleChangeStatus();
          } else {
            return;
          }
        } else {
          await handleChangeStatus();
        }
      } else {
        // 3. 예약 -> 예약으로 변경하는 경우
        if (changeStatus.code === AppointmentStatus.canceled) {
          //예약 -> 예약취소
          await api.cancelSessions(appointment.id);
        } else {
          if (changeStatus.code === AppointmentStatus.scheduled) {
            const res = await api.getSmsNotifications();
            const smsRulesJson = await res.json();

            const smsRules = smsRulesJson.data.filter(
              (f: any) => f.smsScheduleType === "immediate"
            );

            if (
              smsRules.length > 0 &&
              new Date(appointment.startAt) > new Date()
            ) {
              checkImmediateSms(smsRules, appointment);
              return;
            }
          }
          try {
            await api.editAppointment(appointment.id, {
              status: changeStatus.code,
            });
          } catch (e: any) {
            if (e.code === 409 && e.message === "EXTERNAL UPDATE CONFLICT") {
              return sb.open(externalUpdateConflictErrorMessage);
            }
            return sb.open("예약 변경에 실패했습니다.");
          }
        }
      }
    } else {
      // 4. 접수 -> 접수로 변경하는 경우 PUT /registrations
      const payload = {
        status:
          changeStatus.code === AppointmentStatus.complete
            ? `${appointment.category}_DONE`
            : changeStatus.code,
      };
      if (changeStatus.type === AppointmentStatus.additionalStatus) {
        await api.editRegistration(appointment.id, {
          status: AppointmentStatus.additionalStatus,
          sessionStatusConfigId: changeStatus.id,
        });
      } else {
        if (changeStatus.code === AppointmentStatus.scheduled) {
          const res = await api.getRegistration(appointment.id);
          const registration = await res.json();
          if (registration.data.appointment === null) {
            sb.open("당일 접수는 예약으로 변경할 수 없습니다.");
            return;
          } else if (registration.data.charts.length > 0) {
            sb.open(
              "연결된 등록차트가 있어 취소가 불가능합니다. (연결해제 후 가능)"
            );
            return;
          } else {
            if (
              registration.data.appointment.department.id !==
                appointment.department.id ||
              registration.data.appointment.startAt !== appointment.startAt
            ) {
              sb.open("이미 접수된 건입니다. 상태를 확인하세요.");
              return;
            }
          }
          const resp = await api.revertRegistration(appointment.id);
          updated = await resp.json();
        } else if (
          changeStatus.code === AppointmentStatus.registrationCanceled
        ) {
          //접수 취소
          // 접수와 연동된 건인지 확인
          const res = await api.getRegistration(appointment.id);
          const registration = await res.json();
          if (registration.data.charts.length > 0) {
            sb.open(
              "연결된 등록차트가 있어 취소가 불가능합니다. (연결해제 후 가능)"
            );
            return;
          }
          if (
            appointment.status === AppointmentStatus.leave ||
            appointment.status.indexOf("DONE") > -1
          ) {
            sb.open("이미 완료된 건입니다. 상태를 확인하세요.");
            return;
          }

          const confirmed = await imperativeModal.open((close) => (
            <ConfirmModal
              body={
                <>
                  접수가 취소됩니다.
                  <br />
                  예약 상태로 변경하려면 상세화면에서 [예약전환] 처리하세요.
                </>
              }
              confirmText="확인"
              onClose={() => close(true)}
              onConfirm={async () => {
                await api.cancelSessions(appointment.id);
                sb.open("접수가 취소되었습니다.");
                close();
              }}
              open={true}
            />
          ));
          if (confirmed) {
            return;
          }
        } else {
          const msg = onChangeStatusCheck(status, changeStatus.code);
          if (msg !== undefined) {
            sb.open(msg);
            return;
          }
          await api.editRegistration(appointment.id, payload);
        }
      }
    }
    updateAppointment(id, { status: changeStatus.code, ...updated?.data });
    loadCount(date, departmentId);
  };

  return {
    date,
    departmentId,
    departments,
    appointments,
    count,
    page,
    reload,
    order,
    updateDepartmentId,
    updateOrder,
    updatePage,
    updateAppointment,
    onChangeStatus,
    setDepartmentId,
    setPage,
  };
};
