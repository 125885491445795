import { Button, IconButton } from "@mui/material";
import { keyed } from "hoc/keyed";
import { useQuery } from "hooks/useQuery";
import { useApi } from "providers/api";
import { format } from "date-fns";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useSnackbar } from "SnackbarProvider";
import { ActionPage } from "./ActionPage";
import Icon from "components/Icon";
import CircularProgress from "@mui/material/CircularProgress";
import { ApiException } from "core/apiClient";
import { ImageEditor } from "image-editor";
import { Box, Stack, Typography } from "./FileEditPage.styled";
import { ageText } from "utils/filters";
import {
  getFileExtensionFromBlob,
  getImageDimensions,
  bytesToSize,
} from "utils/fileUtil";
import { getMaxDimesionSize } from "utils/file";

const { width, height } = getMaxDimesionSize();

type TitleProps = {
  files: any[];
  file: any;
  customer?: any;
  onNavigate: (value: any) => void;
};

const Title = ({ files, file, customer, onNavigate }: TitleProps) => {
  const title = file?.name ?? "";

  if (!files || !file)
    return (
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        {title}
      </Typography>
    );

  const index = files.indexOf(file.id);

  const onClickPrev = (e: any) => {
    e.stopPropagation();
    onNavigate(files[index - 1]);
  };

  const onClickNext = (e: any) => {
    e.stopPropagation();
    onNavigate(files[index + 1]);
  };

  return (
    <Stack flexDirection={"column"}>
      <Box className="title-wrapper">
        <IconButton disabled={index === 0} onClick={onClickPrev}>
          <Icon variant="arrow_left_fill" />
        </IconButton>
        <Typography className="title" variant="h6">
          {title}
        </Typography>
        <IconButton disabled={index === files.length - 1} onClick={onClickNext}>
          <Icon variant="arrow_right_fill" />
        </IconButton>
      </Box>
      {customer && (
        <Box className="customer-info">{`${customer?.name || "-"}(${
          customer.chartNo || "-"
        }/${customer.sex === "male" ? "M" : "F"}/${ageText(customer)}/${
          customer.birthday
            ? format(new Date(customer.birthday), "yyMMdd")
            : "-"
        }/${
          customer.type === "domestic"
            ? "내국인"
            : customer.type === "foreigner"
            ? "외국인"
            : "" || "-"
        })`}</Box>
      )}
    </Stack>
  );
};

export const FileEditPage = keyed(() => {
  const { id } = useParams(); // Unpacking and retrieve id
  const [searchParams] = useSearchParams();
  const [saveFlag, setSaveFlag] = useState(false);
  const query = useQuery();
  const fileIds: any = query.get("files")?.split(",").map(Number);
  const [saving, setSaving] = useState(false);
  const [customer, setCustomer] = useState<any>();
  const [file, setFile]: any = useState(null);
  const [error, setError] = useState<null | string>(null);
  const editorRef: any = useRef();
  const nav = useNavigate();
  const snackbar = useSnackbar();
  const api = useApi();

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    loadCustomer();
  }, []);

  const loadCustomer = useCallback(async () => {
    const customerId = searchParams.get("customerId");
    if (!customerId) return;

    try {
      const res = await api.getCustomer(customerId);
      const payload = await res.json();
      setCustomer(payload.data);
    } catch (e) {
      console.log(e);
    }
  }, [searchParams]);

  const handleBeforeUnload = (event: Event) => {
    event.preventDefault();
    return "";
  };

  const confirmLeaving = async () => {
    const prevHistoryBtnDisabled = document.querySelector<HTMLButtonElement>(
      '.toolbar-btn-wrapper[aria-label="실행취소"]>button'
    )?.disabled;
    if (saveFlag || prevHistoryBtnDisabled) return true;
    const confirm = window.confirm("변경 사항이 있습니다. 저장하시겠습니까?");
    if (confirm) {
      if (editorRef.current && editorRef.current.isDirty()) {
        await save();
      }
    }
    return confirm;
  };

  const loadFile = useCallback(async () => {
    try {
      const res = await api.getFile(id);
      const payload = await res.json();

      setFile(payload.data);

      if (payload.data.image.thumbnailStatus === "FAILED") {
        setError("지원하지 않는 이미지 파일입니다.");
      }
    } catch (e) {
      if (e instanceof ApiException && e.code === 404) {
        setError("존재하지 않거나 삭제된 파일입니다.");
      } else {
        setError("파일을 불러오는데 실패했습니다.");
        throw e;
      }
    }
  }, [api, id]);

  const getPresignedData = async (fileExtension: string) => {
    const response = await api.createImage(fileExtension);
    return await response.json();
  };

  const validateImage = (blob: Blob, originalUrl: string) => {
    return new Promise((resolve, reject) => {
      if (!blob.type.startsWith("image/")) {
        reject(new Error("올바른 이미지 파일이 아닙니다."));
      }
      const img = new Image();
      img.onload = () => {
        if (img.width === 0 || img.height === 0) {
          reject(
            new Error("유효하지 않은 이미지입니다: 너비 또는 높이가 0입니다.")
          );
        } else {
          fetch(originalUrl)
            .then((response) => response.blob())
            .then((originalBlob) => {
              const sizeRatio = blob.size / originalBlob.size;
              if (sizeRatio < 0.6) {
                reject(
                  new Error(
                    `이미지 크기가 변경되었습니다. 원본 대비 ${(
                      sizeRatio * 100
                    ).toFixed(2)}%입니다.`
                  )
                );
              } else {
                resolve({ width: img.width, height: img.height, sizeRatio });
              }
            })
            .catch((error) => {
              console.error("원본 이미지 비교 중 오류 발생:", error);
              resolve({ width: img.width, height: img.height, sizeRatio: 1 });
            });
          resolve({ width: img.width, height: img.height });
        }
      };
      img.onerror = () => reject(new Error("손상된 이미지 파일입니다."));
      img.src = URL.createObjectURL(blob);
    });
  };

  const save = async () => {
    try {
      const blob = await editorRef.current.toBlob();
      const {
        data: { presignedUrl, token },
      } = await getPresignedData("png");
      await validateImage(blob, file.image.originalUrl);
      const _file = new File([blob], file.name, { type: blob.type });
      await api.uploadImage(presignedUrl, _file);
      const resp = await api.uploadImages(token);
      const result = await resp.json();
      await api.updateFile(file.id, { imageId: result.data.id });
    } catch (error) {
      console.error("image upload failed: " + error);
      snackbar.open(
        "이미지 처리 중에 오류가 발생하여 저장할 수 없습니다. 목록으로 이동한 뒤 이미지를 다시 불러와서 작업해 주세요."
      );
    }
  };

  const onClickSave = async () => {
    if (saving || !file || error) {
      return;
    }
    if (!editorRef.current || editorRef.current.isBusy()) {
      return snackbar.open("아직 에디터를 불러오는 중입니다.");
    }
    try {
      setSaveFlag(true);
      setSaving(true);
      await save();
      snackbar.open("이미지를 성공적으로 업데이트했습니다.");
      nav(-1);
    } catch (e) {
      snackbar.open("이미지 저장에 실패했습니다.");
      throw e;
    } finally {
      setSaving(false);
    }
  };

  const onCancel = async () => {
    await confirmLeaving();
    nav(-1);
  };

  useEffect(() => {
    loadFile();
  }, [loadFile]);

  useEffect(() => {
    if (error || !file) return;
    if (file.image.originalUrl) return;
    const interval = setInterval(async () => {
      loadFile();
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [error, file, loadFile]);

  const onNavigate = async (fileId: string) => {
    await confirmLeaving();
    nav(
      `/files/${fileId}/edit?${
        customer ? `customerId=${customer.id}&` : ""
      }files=${fileIds.join(",")}`,
      {
        replace: true,
      }
    );
  };

  const onLoadBoilerplates = useCallback(async () => {
    const res = await api.getPenchartBoilerplates();
    const payload = await res.json();
    return payload.data;
  }, [api]);

  const onDeleteBoilerplate = useCallback(
    async (id: string) => {
      await api.deletePenchartBoilerplate(id);
      snackbar.open("상용구를 삭제했습니다.");
    },
    [api, snackbar]
  );

  const onSaveBoilerplate = useCallback(
    async (contents: string) => {
      await api.createPenchartBoilerplate(contents);
      snackbar.open("문구를 상용구로 저장했습니다.");
    },
    [api, snackbar]
  );

  const handleLoadBoilerplateBookmarkImage = useCallback(async () => {
    const res = await api.getBookmarksImageList();
    const payload = await res.json();
    return payload.data.map(
      (item: {
        id: number;
        image: { originalUrl: string; thumbnailUrl: string }[];
      }) => {
        return { id: item.id, contents: item.image };
      }
    );
  }, [api]);

  const handleDeleteBoilerplateBookmarkImage = useCallback(
    async (id: string) => {
      await api.deleteBookmarkImage(id);
      snackbar.open("자주쓰는 이미지를 삭제했습니다.");
    },
    [api, snackbar]
  );

  const handleSaveBoilerplateBookmarkImage = useCallback(
    async (file: Blob) => {
      const dimensions: any = await getImageDimensions(file as File);
      const { size } = await bytesToSize(file.size, "MB");
      if (
        dimensions?.width > width ||
        dimensions?.height > height ||
        size > 50
      ) {
        return snackbar.open(
          <>
            이미지 파일 크기가 50MB를 초과하거나,
            <br />
            가로 또는 세로길이가 4096px을 초과하는 경우에는 불러올 수 없습니다.
          </>
        );
      }
      const getPresignedData = async (fileExtension: string) => {
        const response = await api.createBookmarkImage(fileExtension);
        return await response.json();
      };
      const uploadFile = async (file: Blob) => {
        const fileExtensionType = getFileExtensionFromBlob(file);
        const {
          data: { presignedUrl, token },
        } = await getPresignedData(fileExtensionType || "png");
        await api.uploadBookmarkImageFile(presignedUrl, file);
        await api.uploadBookmarkImage(token);
      };
      try {
        await uploadFile(file);
        snackbar.open("자주쓰는 이미지를 저장했습니다.");
      } catch (error) {
        snackbar.open("에러가 발생해 이미지가 저장되지 않았습니다.");
      }
    },
    [api, snackbar]
  );

  const boilerplateProp = useMemo(
    () => [
      {
        onLoadBoilerplate: onLoadBoilerplates,
        onDeleteBoilerplate: onDeleteBoilerplate,
        onSaveBoilerplate: onSaveBoilerplate,
      },
      {
        onLoadBoilerplate: handleLoadBoilerplateBookmarkImage,
        onDeleteBoilerplate: handleDeleteBoilerplateBookmarkImage,
        onSaveBoilerplate: handleSaveBoilerplateBookmarkImage,
      },
    ],
    [onDeleteBoilerplate, onLoadBoilerplates, onSaveBoilerplate]
  );

  return (
    <ActionPage
      title={
        <Title
          files={fileIds}
          file={file}
          onNavigate={onNavigate}
          customer={customer}
        />
      }
      customer={customer}
      onCancel={onCancel}
      trailingButton={
        <Button
          size="large"
          onClick={onClickSave}
          disabled={(saving || !file || error) as boolean}
          color="primary"
          sx={{ height: "34px", whiteSpace: "nowrap" }}
        >
          {saving ? <CircularProgress size={24} /> : <span>저장</span>}
        </Button>
      }
    >
      {!error && file?.image.originalUrl && (
        <Box className="image-editor-wrapper">
          {saving && <Box className="coating"></Box>}
          <ImageEditor
            ref={editorRef}
            imageUrl={file.image.originalUrl}
            boilerplate={boilerplateProp as any}
          />
        </Box>
      )}
      {!error && !file?.image.originalUrl && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <CircularProgress />
        </Box>
      )}
      {error && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <Typography>{error}</Typography>
        </Box>
      )}
    </ActionPage>
  );
}, ["id"]);
